import React, { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
export const Contact = () => {
  const inputRef = useRef(null)
  const emailRef = useRef(null)
  const phoneRef = useRef(null)
  const messageRef = useRef(null)

  const placeholderHighlight = ["one", "two", "three"];
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const timer = () => {
      setIndex(prevIndex => {
        if (prevIndex === placeholderHighlight.length-1) {
          return 0;
        }
        return prevIndex + 1;
      })

    };
    setInterval(timer, 3000);
    return () => { clearInterval(timer); }
  }, [])

  const isValidEmail = (inputEmail) => {
    return /\S+@\S+\.\S+/.test(inputEmail);
  }
  const [input_name, setName] = useState("");
  const [input_email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const sendForm={
    input_name: input_name,
    input_email: input_email,
    phone: phone,
    message: message
  }

  const service_id="service_xcjhxjm"
  const template_id="template_ix32cj6"
    const public_key="Obj2B5j1AKUvCwT5a"
  const submitContact = () => {

    let nameLength;
    let emailLength;
    let validEmail;
    let phoneLength;
    let messageLength;

    if (input_name.trim().length == 0) {
      nameLength = false;
      alert("Please Enter Full Name")
    } else {

      nameLength = true;
    }

    if (nameLength) {
      if (input_email.trim().length == 0) {
        emailLength = false;
        alert("Please Enter Email")
      } else {
        emailLength = true;
      }
    }
    if (emailLength) {
      if (isValidEmail(input_email)) {
        validEmail = true;

      } else {
        validEmail = false;
        alert("Please Enter a valid Email")
      }
    }
    if(validEmail){
      if(phone.trim().length == 0){
        phoneLength = false
        alert("Please Enter Phone Number")
      }else{
        phoneLength = true;
      }
    }
    if (phoneLength) {
      if (message.trim().length == 0) {
        messageLength = false;
        alert("Please Enter message")
      } else {
        messageLength = true;
      }
    }
    
    if (nameLength && emailLength && validEmail && phoneLength && messageLength) {
      emailjs.send(service_id, template_id, sendForm, public_key)
      .then((response)=>{
        setName("")
        setEmail("")
        setPhone("")
        setMessage("")
        alert("Thank you. Your message has been sent successfully")
      }).catch((error)=>{
        console.log(error)
      })
    }
  }


  return (
    <div className='contact-container' id='contact'>
      <div className='contact-wrap'>
        <div className='contact-header'>Contact Our Team</div>
        <div className='contact-desc'>Whether you're a marketing pro looking for a smarter way to manage campaign expenses or someone seeking a more efficient way to handle personal purchases, our cards provide the flexibility, control, and security you need.</div>
        <div className='contact-team'>
          <div className='team'>
            <div className='team-desc'>
              <div className='desc-wrap'>
                <div className='team-num'>
                  <div className='t-1' id={index == 0 ? 'bg' : null}>1</div>
                </div>
                <div className='team-text'>

                  <div className='t-header' id={index == 0  ? 'active-h' : null}>Contact Our Team</div>
                  <div className='t-desc' id={index == 0  ? 'active-desc' : null}>We're here for you! Whether you're starting out or need support, our team is ready to assist. Reach out for personalized guidance and get quick answers to your questions.</div>
                </div>
                <div className="side-margin"></div>
              </div>
            </div>

            <div className='team-desc'>
              <div className='desc-wrap'>
                <div className='team-num'>
                  <div className='t-1' id={index == 1  ? 'bg' : null}>2</div>
                </div>
                <div className='team-text'>
                  <div className='t-header' id={index == 1  ? 'active-h' : null}>Your Card Solution</div>
                  <div className='t-desc' id={index == 1 ? 'active-desc' : null}>Get the card that fits your needs—whether for personal or business use. Our solutions offer security, flexibility, and global access.</div>
                </div>
              </div>
            </div>

            <div className='team-desc'>
              <div className='desc-wrap'>
                <div className='team-num'>
                  <div className='t-1' id={index == 2 ? 'bg' : null}>3</div>
                </div>
                <div className='team-text'>
                  <div className='t-header' id={index == 2 ? 'active-h' : null}>Complete Verification and Activate Solution</div>
                  <div className='t-desc' id={index == 2 ? 'active-h' : null}>Quick and easy setup! Complete your verification and activate your card in just a few steps.
                    <div className='t-step' id={index == 2 ? 'active-desc' : null}>
                      <ul><li>Step 1: Submit your details.</li></ul>
                      <ul><li>Step 2: Securely verify your identity.</li></ul>
                      <ul><li>Step 3: Activate and start using your card immediately!</li></ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className='contact-form'>
            <div className='form-1'>
              <div className='f-name' onClick={() => inputRef.current.focus()}>
                <div className='f-name-label'>Full Name</div>
                <div className='f-name-input'><input type="text" ref={inputRef} value={input_name} onChange={(e) => setName(e.target.value)}/></div>
              </div>

              <div className='f-name' onClick={() => emailRef.current.focus()}>
                <div className='f-name-label'>Email</div>
                <div className='f-name-input'><input type="text" ref={emailRef} value={input_email} onChange={(e) => setEmail(e.target.value)}/></div>
              </div>

              <div className='f-name' onClick={() => phoneRef.current.focus()}>
                <div className='f-name-label'>Phone Number</div>
                <div className='f-name-input'><input type="text" ref={phoneRef} value={phone} onChange={(e) => setPhone(e.target.value)}/></div>
              </div>

              <div className='f-message' onClick={() => messageRef.current.focus()}>
                <div className='f-name-label'>Message</div>
                <div className='f-name-input'><textarea type="text" ref={messageRef} maxLength={530} value={message} onChange={(e) => setMessage(e.target.value)}/></div>
              </div>

              <div className='contact-btn-wrap'>
                <div className='contact-btn' onClick={submitContact}>Send</div>
              </div>
            </div>
            <div className='form-2'></div>
          </div>
        </div>

      </div>
    </div>
  )
}
