import React from 'react'
import { Link } from 'react-scroll'

export const Unlimited = () => {
  return (
    <div className='unlimited-container' id='part'>
        <div className='unlimited-wrap'>
            <div className='u-desc-wrap'><div className='u-desc'>Be part of the journey through unlimited spending and boundless opportunity</div></div>
            <Link className='u-btn-wrap' to='contact' spy={false} smooth={true} offset={-100} duration={800}><div className='u-btn'>Contact Sales</div></Link>
        </div>
    </div>
  )
}
