import React, { useContext } from 'react'
import NavContext from '../context/NavContext';
import { Link } from 'react-scroll';

export const MobileNav = () => {
    const {hamburger, setHamburger} = useContext(NavContext);
  return (
    <div className='m-nav-container' id={hamburger ? 'slide-in' : null}>
        <div className="m-nav-wrap">
            <div className="m-list">
                <Link className="ml-1" to='footer' spy={false} smooth={true} offset={-100} duration={800}><div onClick={()=>setHamburger(false)}>About Us</div></Link>
                <Link className="ml-1" to='choose-card' spy={false} smooth={true} offset={-100} duration={800}><div onClick={()=>setHamburger(false)}>Features</div></Link>
                <Link className="ml-1" to='contact' spy={false} smooth={true} offset={-100} duration={800}><div onClick={()=>setHamburger(false)}>Contact Us</div></Link>
            </div>
        </div>
    </div>
  )
}
