import React, { useContext } from 'react'
import logo from '../icons/logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-scroll'
import NavContext from '../context/NavContext'
export const Nav = () => {
  const { hamburger, setHamburger } = useContext(NavContext);
  const clickHamburger = () => {
    setHamburger(!hamburger)

  }
  return (
    <div className='nav-container'>
      <div className='echo'>
        <div className='logo'><img src={logo} alt="" /></div>
        <div className='echo-text'>Echodydx</div>
      </div>
      <div className='nav-list'>
        <div className='item'><Link to='footer' spy={false} smooth={true} offset={-100} duration={800}>About Us</Link></div>
        <div className='item'><Link to='choose-card' spy={false} smooth={true} offset={-100} duration={800}>Features</Link></div>
        <div className='item'><Link to='contact' spy={false} smooth={true} offset={-100} duration={800}>Contact Us</Link></div>
      </div>

      <Link className='nav-button' to='contact' spy={false} smooth={true} offset={-100} duration={800}>
        <div className='btn-1'>Contact Us</div>
        <div className='btn-2'><FontAwesomeIcon icon={faBars} onClick={clickHamburger} /></div>
      </Link>

    </div>
  )
}
